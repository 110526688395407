import React from 'react';
import { useRouter } from 'next/router';
import { useLocale } from 'next-intl';

export const LanguageSwitcher = () => {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const locale = useLocale();

  const setLocale = (lang: 'en' | 'id') => {
    router.push({ pathname, query }, asPath, { locale: lang });
  };

  return (
    <div
      className="flex items-center fixed bottom-4 right-4 space-x-1 bg-gray-2 bg-opacity-30 rounded-md px-2"
      style={{
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '16px',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(5px)',
        border: '1px solid rgba(255, 255, 255, 0.3);',
      }}
    >
      <button onClick={() => setLocale('en')} className={locale === 'en' ? 'text-primary' : ''}>
        en
      </button>
      <p>|</p>
      <button onClick={() => setLocale('id')} className={locale === 'id' ? 'text-primary' : ''}>
        id
      </button>
    </div>
  );
};
