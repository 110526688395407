import universities from './universities.json';
import countries from './countries.json';

export * from './dropdowns';
export * from './experiences';
export * from './timezones';
export * from './circle-preset';
export * from './terms-of-use';
export * from './persona-quiz';
export * from './persona';
export * from './mobile-app';
export { universities };
export { countries };

export const FETCH = 18;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const FIRST_AVATAR =
  'https://d2wefhtvovcf6q.cloudfront.net/profiles/first_avatar-202203120932320000';
export const UNSPLASH_URL = 'https://api.unsplash.com';
export const UNSPLASH_TOKEN = 'Client-ID SF4z19tbo661wD1RiM3wIaHL_orJ8k4OTK2sEdtAuOU';
export const GOOGLE_API_KEY = 'AIzaSyD05sHgmbpbUYNJewi2TpDKBcCJxHtBprk';

export const MONTH_NAME = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const VideoProvider = {
  DAILYCO: 'DailyCo',
  VIDEOSDK: 'VideoSdk',
};

/**
 * @param {Activity} activity
 */
export const isOngoing = (activity) => {
  const activityStart = new Date(activity.startOn);
  const activityEnd = new Date(activity.endOn);
  const now = new Date();

  const fifteenMinutesPrior = activityStart.valueOf() - now.valueOf() < 1000 * 60 * 15;
  const haventEnded = now < activityEnd;

  return activity.isJoined && fifteenMinutesPrior && haventEnded;
};

export const toCircleFrom = (subpage, type = '') => {
  if (['Guideline'].includes(subpage) && ['BLIND_DATE'].includes(type)) {
    return subpage;
  }

  if (!['Chat', 'Member', 'Activity', 'Attend'].includes(subpage)) {
    return 'Chat';
  }

  return subpage;
};

export const toHomeFrom = (subpage) => {
  // if (subpage === 'Experience') return subpage;
  if (
    subpage != 'Circle' &&
    subpage != 'Activity / Personal' &&
    subpage != 'Activity / Discover' &&
    subpage != 'Start'
  ) {
    return 'Start';
  }
  return subpage;
};

export const toProfileFrom = (subpage) => {
  if (
    subpage != 'Profile' &&
    subpage != 'Edit Profile' &&
    subpage != 'Change Email' &&
    subpage != 'Change Password' &&
    // TODO
    // subpage != 'Connected Apps' &&
    subpage != 'Privacy Policy'
  ) {
    return 'Profile';
  }
  return subpage;
};

export const toOtherProfile = (subpage) => {
  if (
    subpage != 'Profile' &&
    subpage != 'Start' &&
    subpage != 'Activity / Discover' &&
    subpage != 'Circle'
  ) {
    return 'Profile';
  }
  return subpage;
};

export const privacyPolicy = [
  {
    title: 'Information we collect',
    description:
      'We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like circles and activities that you like the most, or the people who matter most to you online. The information we collect, and how that information is used, depends on how you use our services and how you manage your privacy controls.\nWhen you’re signed in, we collect information that we store with your Socles Account, which we treat as personal information.',
  },
  {
    title: 'Why we collect data',
    description:
      'Socles will require you to give certain information to provide our services for you. The data will be used only to operate our services. Through that data, you could help us to provide better features and experiences while using our platform.',
  },
  {
    title: 'Your privacy control',
    description:
      'We believe that users should be treated equally no matter where they are, and so we are making the following options to control your data available to all users, regardless of their location. You can update certain information by accessing your profile via “Settings.”',
  },
  {
    title: 'Sharing your information',
    description:
      'Our services let you share information with other people, and you have control over how you share. Socles will not share your personal information with any party except in the following cases:\n- With your consent. We’ll ask for your explicit consent to share any sensitive personal information.\n- With domain administrators.\n- For external processing.\n- For legal reasons.\nWe may share non-personally identifiable information publicly and with our partners. For example, we share information publicly to show trends about the general use of our services.',
  },
  {
    title: 'Managing Your Information',
    description:
      "You have the right to delete your account and all of the information which is stored with it at any given time. You can do so by selecting \"Delete\" button on your profile page. Otherwise we store data until it is no longer necessary to provide our services or until your account is deleted – whichever comes first. This is a case-by-case determination that depends on things such as the nature of the data, why it is collected and processed, and relevant legal or operational retention needs. \n When you delete your account, we delete all of the things that you have created and related to you, such as your circles, activities, and chats, and you won't be able to recover this information later. Information that others have created within the same circle with you that isn't part of your account won't be deleted. If you don't want to delete your account but want to temporarily stop using Socles, you can deactivate your account instead.",
  },
  {
    title: 'Keeping your information secure',
    description:
      'Socles is built with strong security features that continuously protect your information. If we do detect something risky that we think you should know about, we’ll notify you and help guide you through steps to stay better protected.',
  },
  {
    title: 'About this policy',
    description:
      'This Privacy Policy applies to all of the services offered by Socles. This Privacy Policy doesn’t apply to services that have separate privacy policies that do not incorporate this Privacy Policy.\nWe may update this Privacy Policy from time to time without prior notice.',
  },
];

export const clickableNotificationCategories = [
  'request_join_circle',
  'accept_join_circle',
  'openchannel_message',
  'new_user_activity',
  'interest_new_activity',
  'circle_new_member_intro',
];
