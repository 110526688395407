import FontFaceObserver from 'fontfaceobserver';

const loadFonts = async () => {
  const socles = new FontFaceObserver('Socles', { weight: 400 });

  const playfair = new FontFaceObserver('Playfair Display', { weight: 600 });

  return await Promise.all([socles.load('', 15000), playfair.load('', 15000)]);
};

export default loadFonts;
